import { Chart } from "react-google-charts";
import React from 'react';
import useFetchData from "../../../hooks/useFetchData";

const ProfitExpenseNettAllTime = () => {
    const { items: expenses } = useFetchData("https://servers.expressmartbd.com/alltech/monthwiseexpenseincome");
    const { items: profit } = useFetchData("https://servers.expressmartbd.com/alltech/alltimeprofit");

    const totalProfit = profit?.reduce((total, currentValue) => total + parseInt(currentValue?.profit), 0);
    const totalExpense = expenses?.reduce((total, expense) => {
        if (expense?.alltimeexpense) {
            return total + parseFloat(expense.alltimeexpense);
        }
        return total;
    }, 0) || 0;
    const totalIncome = expenses?.reduce((total, income) => {
        if (income?.alltimeincome) {
            return total + parseFloat(income.alltimeincome);
        }
        return total;
    }, 0) || 0;

    const netProfit = totalProfit + totalIncome - totalExpense;

    console.log(netProfit,totalExpense);

    const data = [
        ["Task", "Expense Profit Ratio"],
        ["Profit", netProfit],
        ["Expense", totalExpense],
    ];

    const options = {
        title: "Profit Expense Ratio (All Time)",
        is3D: true,
    };
    return (
        <div className="mt-8">
            <Chart
                chartType="PieChart"
                data={data}
                options={options}
                width={"100%"}
                height={"300px"}
            />
        </div>
    );
};

export default ProfitExpenseNettAllTime;