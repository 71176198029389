import logo from '../../assets/images/logo.jpg'

const BusinessInfo = {
    name: "All Tech Battery",
    fullName: "All Tech Battery and Tyres",
    dealer: "(Authorized Dealer Of Rafimafroz)",
    address: "Bus Stand, Mawna Chowrasta, Sreepur, Gazipur",
    mobile: "Mobile: 01404010517,01404010518",
    img: logo,
}

export default BusinessInfo;
